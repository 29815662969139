import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

const sortReceipts = (data, key, order) => {
  const output = JSON.parse(JSON.stringify(data));
  output.sort((receipt1, receipt2) => {
    switch (key) {
      case 'company':
        return receipt1.company.localeCompare(receipt2.company, 'en', { sensitivity: 'base' });
      case 'date':
        return Number(receipt1.createdAt) - Number(receipt2.createdAt);
      case 'location':
        return receipt1.location.localeCompare(receipt2.location, 'en', { sensitivity: 'base' });
      default:
        return receipt1.createdAt - receipt2.createdAt;
    }
  });
  return order === 'asc' ? output : output.reverse();
};

const useStore = create(
  devtools((set) => ({
    user: undefined,
    profile: undefined,
    receipts: undefined,
    sortKey: 'date',
    sortOrder: 'asc',
    sortedReceipts: undefined,
    updateUserLogin: (data) => set(() => ({ user: data })),
    updateUserProfile: (data) => set(() => ({ profile: data })),
    logoutUser: () => set(() => ({ user: undefined, profile: undefined })),
    updateReceipts: (data) =>
      set((state) => ({
        receipts: data,
        sortedReceipts: sortReceipts(data, state.sortKey, state.sortOrder)
      })),
    updateSortKey: (data) =>
      set((state) => ({
        sortKey: data,
        sortedReceipts: sortReceipts(state.receipts, data, state.sortOrder)
      })),
    updateSortOrder: (data) =>
      set((state) => ({
        sortOrder: data,
        sortedReceipts: sortReceipts(state.receipts, state.sortKey, data)
      }))
  }))
);

export default useStore;

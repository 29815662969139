import React, { useEffect, useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import useStore from '../store';
import Receipt from '../components/receipt';
import { getApiUrl } from '../utlis/env';

const Login = () => {
  const [showModal, setShowModal] = useState(false);
  const updateUserLogin = useStore((state) => state.updateUserLogin);
  const user = useStore((state) => state.user);
  const updateUserProfile = useStore((state) => state.updateUserProfile);
  const profile = useStore((state) => state.profile);

  const login = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async ({ code }) => {
      const response = await axios.post(`${getApiUrl()}auth/google`, {
        code
      });
      updateUserLogin(response.data);
    },
    onError: (error) => console.log('Login Failed:', error)
  });

  useEffect(() => {
    if (user) {
      axios
        .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            Accept: 'application/json'
          }
        })
        .then((res) => {
          updateUserProfile(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [user, updateUserProfile]);

  return (
    <>
      {profile?.id ? (
        <Receipt />
      ) : (
        <div className="container" style={{ padding: '32px' }}>
          <img src="/assets/images/logo.png" style={{ maxWidth: '82vw' }} className="mt-2 mb-3" />
          <p> Manage all the stores receipt and never miss a return!</p>
          <Button variant="link" onClick={() => setShowModal(!showModal)}>
            {' '}
            How does this work{' '}
          </Button>
          <br />
          <br />
          <Button variant="primary" onClick={() => login()}>
            Sign in with Google 🚀{' '}
          </Button>
          <Modal show={showModal} onHide={() => setShowModal(false)} centered>
            <Modal.Header closeButton>
              <Modal.Title>How does this work ?!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Click a picture of the receipt, add some basic info and you never have to miss a
              return ever!!!!!
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  );
};

export default Login;

const getApiUrl = () => {
  if (window.location.href.includes('receipttrunk')) {
    return 'https://yl6y8ku454.execute-api.us-east-1.amazonaws.com/staging/';
  } else {
    return 'http://localhost:9000/';
  }
};

module.exports = {
  getApiUrl
};

import React, { useEffect, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import ListGroup from 'react-bootstrap/ListGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import { googleLogout } from '@react-oauth/google';
import useStore from '../../store';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { getApiUrl } from '../../utlis/env';

const Receipt = () => {
  // random sddasdddds s
  const user = useStore((state) => state.user);
  const profile = useStore((state) => state.profile);
  const logoutUser = useStore((state) => state.logoutUser);
  const updateReceipts = useStore((state) => state.updateReceipts);
  const sortedReceipts = useStore((state) => state.sortedReceipts);
  const sortKey = useStore((state) => state.sortKey);
  const updateSortKey = useStore((state) => state.updateSortKey);
  const sortOrder = useStore((state) => state.sortOrder);
  const updateSortOrder = useStore((state) => state.updateSortOrder);
  // local state
  const [showNrModal, setShowNrModal] = useState(false);
  const [company, setCompany] = useState('');
  const [notes, setNotes] = useState('');
  const [location, setLocation] = useState('');
  const [image, setImage] = useState(undefined);

  useEffect(() => {
    // blah 123 dssq fs 2222
    // no-debuggerddd1 22w dwq
    axios
      .get(`${getApiUrl()}api/receipts`, {
        headers: {
          Authorization: user.id_token,
          Accept: 'application/json'
        }
      })
      .then((res) => {
        console.log('fdsfsdfdfsfdfs', res.data);
        updateReceipts(res.data);
      })
      .catch((err) => console.log(err));
  }, [user, updateReceipts]);

  // log out function to log the user out of
  // google and set the profile array to null
  const logOut = () => {
    googleLogout();
    logoutUser();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    console.log(company, location, notes, image);
    const formData = new FormData();
    formData.append('company', company);
    formData.append('notes', notes);
    formData.append('location', location);
    formData.append('image', image);
    formData.append('createdAt', Date.now().toString());

    try {
      const response = await axios.post('http://localhost:9000/api/receipts', formData, {
        headers: {
          Authorization: user.id_token,
          Accept: 'application/json'
        }
      });
      console.log(response);
    } catch (e) {
      console.error(e);
    } finally {
      // handleClose();
    }
  };

  const handleImageUpload = (event) => {
    setImage(event.target.files[0]);
  };

  const handleClose = () => {
    setImage(undefined);
    setNotes('');
    setLocation('');
    setCompany('');

    setShowNrModal(false);
  };

  const setSortKey = (value) => {
    updateSortKey(value);
  };

  const setSortOrder = () => {
    updateSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  return (
    <>
      <Navbar bg="light" expand="lg">
        <Container fluid>
          <Navbar.Brand href="#">
            <img
              src="/assets/images/logo.png"
              width="200px"
              className="d-inline-block"
              alt="React Bootstrap logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse className="justify-content-end">
            <Nav className="d-flex">
              <Navbar.Text>{profile.name.toLocaleUpperCase()} </Navbar.Text>
              <Button variant="outline-success mx-4" onClick={logOut}>
                Log out
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div>
        <Button className="mt-4" onClick={() => setShowNrModal(true)}>
          {' '}
          Add New Receipt
        </Button>
        <div className="me-3 mt-4">
          <Button className="float-end " variant="link" onClick={setSortOrder}>
            {sortOrder}
          </Button>
          <Dropdown className="float-end mb-2" title={sortKey}>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              {sortKey}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setSortKey('company')}>Company Name</Dropdown.Item>
              <Dropdown.Item onClick={() => setSortKey('date')}>Date</Dropdown.Item>
              <Dropdown.Item onClick={() => setSortKey('location')}>Location</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Table bordered className="mt-4">
          <thead>
            <tr>
              <th>#</th>
              <th>Details</th>
              <th>Thumbnail</th>
            </tr>
          </thead>
          <tbody>
            {sortedReceipts &&
              sortedReceipts.map((receipt, index) => (
                <tr key={`receipt-${index}`}>
                  <td>
                    <p>{index + 1}</p>
                  </td>
                  <td>
                    <ListGroup>
                      <ListGroup.Item>{receipt.company}</ListGroup.Item>
                      {/* <ListGroup.Item>
                        {new Date(Number(receipt.createdAt)).toISOString()}
                      </ListGroup.Item> */}

                      <ListGroup.Item>
                        {new Intl.DateTimeFormat('en-US', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                          hour: '2-digit',
                          minute: '2-digit',
                          second: '2-digit'
                        }).format(receipt.createdAt)}
                      </ListGroup.Item>

                      {receipt.location && <ListGroup.Item>{receipt.location}</ListGroup.Item>}
                      {receipt.notes && <ListGroup.Item>{receipt.notes}</ListGroup.Item>}
                    </ListGroup>
                  </td>
                  <td className="align-items-center">
                    <img
                      src="https://placehold.co/600x400.png"
                      style={{ width: '30vw' }}
                      className="mt-2"
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>

        <Modal show={showNrModal} onHide={handleClose} centered>
          <Modal.Body>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <h3 className="mb-2">Upload Receipt Image</h3>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-2" controlId="newReceipt.receiptImage">
                  <Form.Control
                    type="file"
                    accept=".png,.jpg,.jpeg,.webp,.svg,.heic"
                    onChange={handleImageUpload}
                  />
                </Form.Group>
                <FloatingLabel controlId="newReceipt.company" label="Company" className="mb-2">
                  <Form.Control
                    type="text"
                    placeholder="eg. TjMaxx"
                    onChange={(e) => setCompany(e.target.value)}
                  />
                </FloatingLabel>
                <FloatingLabel controlId="newReceipt.location" label="Location" className="mb-2">
                  <Form.Control
                    type="text"
                    placeholder="eg. Boston, MA"
                    onChange={(e) => setLocation(e.target.value)}
                  />
                </FloatingLabel>
                <FloatingLabel controlId="newReceipt.notes" label="Notes" className="mb-2">
                  <Form.Control
                    as="textarea"
                    placeholder="this is for black theory dress"
                    style={{ height: '100px' }}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </FloatingLabel>
                <Form.Group className="mb-2" controlId="newReceipt.receiptImage">
                  <Button variant="success" type="submit" className="me-3">
                    Submit
                  </Button>
                  <Button variant="secondary" onClick={handleClose}>
                    Cancel
                  </Button>
                </Form.Group>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default Receipt;
